@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  font-family: 'Source Sans Pro', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

@media print {
  #print-page__header {
    position: fixed;
    top: 0;
    width: 100%;
    display: table-header-group;
  }
  #print-page__footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: table-footer-group;
  }
  .print-page__page {
    page-break-inside: avoid;
  }
}
